<template>
  <div>
    <router-view></router-view>
    <!-- Floating Button -->
    <div
      v-if="isFloatingBanner"
      class="floating-wrap on"
      ref="FloatingBanner"
    >
      <router-link
        :to="{ name: 'GangnamReservation' }"
        tag="button"
        class="btn-floating"
      >{{$t('content.center.GangnamReservation.txt16')}}
      <br/>{{$t('content.center.GangnamReservation.txt17')}}</router-link>
    </div>
    <!-- // Floating Button -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { gsap } from '@/common/motions';

export default {
  name: 'Gangnam',
  mixins: [mixinHelperUtils],
  data() {
    return {
      FloatingBannerInitialTop: 150,
    };
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    isFloatingBanner() {
      const { path } = this.$route;
      return !(/\/gangnam\/reservation/.test(path));
    },
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.initFloatingBanner();
      }, 0);
    },
  },
  mounted() {
    this.initFloatingBanner();
  },
  methods: {
    initFloatingBanner() {
      const el = this.$refs.FloatingBanner;
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          y: -150,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          delay: 2,
          ease: 'power1.inOut',
        },
      );
    },
    onScroll() {
      const windowScrollY = window.scrollY || window.pageYOffset || 0; // ie11
      const el = this.$refs.FloatingBanner;
      gsap.to(
        el,
        {
          top: this.FloatingBannerInitialTop + windowScrollY,
          duration: 0.5,
          ease: 'power1.inOut',
        },
      );
    },
  },
};
</script>
